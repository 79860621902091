.add-category-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
}

.add-category-container {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  background: #fff;
  margin: 10px;
  min-width: 560px;

  max-width: 560px;
  border-radius: 12px;
  border: 2px solid #007bffbf;
}

.add-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-category-title-with-img {
  display: flex;
  align-items: center;
  margin-left: -14px;
}

.add-category-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 15px 0px;
  color: #007bff;
}

.add-category-inputs {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  outline: none;
  border: none;
  width: 100%;
}

.select-ui label {
  padding-top: 12px;
}

.add-category-inputs-with-error-message {
  display: flex;
  flex-direction: column;
}

.add-category-error-message {
  color: red;
  margin-top: -4px;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.add_category_Btn {
  width: 86%;
  padding: 1rem;
}

.add-category-header button {
  background: none;
  color: #007bff;
  font-size: 28px;
  box-shadow: none;
  max-height: 25px;
}

.add-category-header button:hover {
  background-color: rgb(245, 243, 243);
  box-shadow: none;
}

.add-category-inputs-with-error-message fieldset {
  border: 1px solid #ccc;
}

.add-category-inputs-with-error-message fieldset:focus-within,
.add-category-inputs-with-error-message fieldset:focus-visible,
.add-category-inputs-with-error-message input:focus-visible,
.add-category-inputs-with-error-message
  input:focus-within
  .add-category-inputs-with-error-message
  input:hover,
.add-category-inputs-with-error-message fieldset:hover {
  outline: none;
  border: none;
}

.add-category-button,
.add-user-button,
.add-category-button {
  text-align: center;
}

.add_doc_content {
  width: 60%;
}

.category-with-color {
  position: relative;
}

.add-category-bg-color {
  position: absolute;
  top: 17px;
  right: 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
}

.add-category-bg-color::-webkit-color-swatch {
  border-radius: 5px;
  border: none;
}

.add-category-bg-color::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.edit-category-bg-color {
  position: absolute;
  top: 8px;
  right: 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
}

.edit-category-bg-color::-webkit-color-swatch {
  border-radius: 5px;
  border: none;
}

.edit-category-bg-color::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.add-category-button button {
  width: 100%;
  margin: 1rem 0;
  background: #000;
  font-weight: 600;
}

.add-category-page .container {
  display: flex;
  flex-direction: row;
  height: 92vh;
  align-items: center;
  justify-content: center;
}
