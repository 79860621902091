.assign-user-doc-select-ui .MuiOutlinedInput-root {
  min-width: 360px;
  max-width: 360px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.assign-user-doc-select-ui .Mui-focused {
  margin-top: 10px;
}

.assign-user-doc-select-ui .MuiFormLabel-filled {
  margin-top: 10px;
}
