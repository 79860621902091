.sidebar-container {
  position: relative;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0px 3px #007bff;
  height: 100vh;
  min-width: 220px;
  max-width: 220px;
  transition: all 0.1s ease;
}

.open-sidebar-img {
  display: flex;
  justify-content: center;
}

.open-sidebar-img img {
  width: 80%;
}

.sidebar-list-section {
  margin-top: 25px;
  max-height: 70vh;
  overflow-y: scroll;
}

.sidebar-list-section::-webkit-scrollbar {
  display: none;
}

.top-section {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 12px 8px;
}

.logo {
  font-size: 10px;
}

.scrollable-bar {
  position: absolute;
  right: -28px;
  top: -2px;
  z-index: 200;
  font-size: 25px;
  cursor: pointer;
}

.sidebar-list {
  display: flex;
  align-items: center;
  gap: 8px;
  color: black;
  padding: 12px 15px;
  margin: 5px 10px;
  /* margin-right: 10px; */
  border-radius: 4px;
}

.submenu-sidebar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: black;
  cursor: pointer;
  padding: 12px 15px;
  /* padding-right: 15px; */
  margin: 5px 10px;
  /* margin-right: 20px; */
  border-radius: 4px;
}

.submenu-sidebar-container:hover {
  background-color: #607d8b29;
}

.submenu-header {
  display: flex;
  gap: 8px;
}

.submenu-list {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 28px;
}

.submenu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: black;
  padding: 8px 12px;
  margin: 2px;
  margin-right: 15px;
  border-radius: 4px;
}

.active-submenu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #2b93c8d0;
  color: black;
  padding: 8px 12px;
  margin: 2px;
  margin-right: 15px;
  border-radius: 4px;
}

.active-submenu-item:hover {
  background-color: #2b93c8d0;
  /* color: white !important; */
}

.submenu-item:hover {
  background-color: #607d8b29;
}

.collapsed-sidebar-container .sidebar-list-section a:last-child {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
}

.sidebar-container .sidebar-list-section a:last-child {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
}

.collapsed-sidebar-container .sidebar-list-section .submenu-list a:last-child {
  position: relative;
  bottom: 0px;
}

.sidebar-container .sidebar-list-section .submenu-list a:last-child {
  position: relative;
  bottom: 0px;
  /* width: 100%; */
  /* width: calc(100% - 26px); */
}

.active-sidebar-list {
  display: flex;
  align-items: center;
  color: black;
  gap: 8px;
  padding: 12px 15px;
  margin: 5px 10px;
  /* margin-right: 20px; */
  border-radius: 4px;
  background-color: #2b93c8d0;
}

.sidebar-list-icon {
  display: flex;
  align-items: center;
}

.sidebar-list-icon svg {
  font-size: 18px;
  text-align: center;
}

.sidebar-list-title {
  display: flex;
  align-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02rem;
  text-align: center;
  transition: all 0.1s ease-in;
}

.hide-sidebar-list-title {
  display: none;
  transition: all 0.1s ease-in;
}

.sidebar-list a {
  text-decoration: none;
}

.sidebar-list:hover {
  background-color: #607d8b29;
  /* color: white !important; */
}

.active-sidebar-list:hover {
  background-color: #2b93c8d0;
  /* color: white !important; */
}

.sidebar-list-title {
  white-space: nowrap;
}

.collapsed-sidebar-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0px 3px #007bff;
  color: black;
  min-height: 100vh;
  min-width: 60px;
  max-width: 60px;
  transition: all 0.1s ease-in;
}

.collapsed-sidebar-container .sidebar-list-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
}

.collapsed-sidebar-container .sidebar-list-section .active-sidebar-list {
  display: flex;
  justify-content: center;
  background-color: #2b93c8d0;
  margin: 5px 0px;
  padding: 10px;
  width: 100%;
}

.collapsed-sidebar-container .sidebar-list-section .sidebar-list {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
  padding: 10px;
  width: 100%;
}

.collapsed-sidebar-container
  .sidebar-list-section
  .collpased-submenu-sidebar-container {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 5px 0px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.collapsed-sidebar-container
  .sidebar-list-section
  .collpased-submenu-sidebar-container:hover {
  background-color: #607d8b29;
}

@media only screen and (max-width: 1300px) {
  .sidebar-container {
    position: relative;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0px 3px #007bff;
    height: 100vh;
    min-width: 176px;
    max-width: 176px;
    transition: all 0.1s ease;
  }

  .open-sidebar-img img {
    width: 80%;
  }

  .sidebar-list-section {
    margin-top: 25px;
    max-height: 60vh;
    overflow-y: scroll;
  }

  .sidebar-list {
    gap: 6px;
    padding: 8px 6px;
    padding-left: 10px;
    margin: 4px 6px;
    border-radius: 3px;
  }

  .active-sidebar-list {
    gap: 6px;
    padding: 8px 6px;
    padding-left: 10px;
    margin: 4px 6px;
    border-radius: 3px;
    background-color: #2b93c8d0;
  }

  .submenu-sidebar-container {
    gap: 6px;
    padding: 8px 6px;
    padding-left: 10px;
    margin: 4px 6px;
    border-radius: 3px;
  }

  .submenu-header {
    display: flex;
    gap: 6px;
  }

  .submenu-list {
    margin-left: 0px;
  }

  .submenu-item {
    gap: 6px;
    color: black;
    padding: 8px 6px;
    padding-left: 8px;

    margin: 3px 6px;
    margin-left: 20px;
    border-radius: 3px;
  }

  .active-submenu-item {
    gap: 6px;
    background-color: #2b93c8d0;
    color: black;
    margin: 3px 6px;
    margin-left: 20px;
    padding: 8px 6px;
    padding-left: 8px;
    border-radius: 3px;
  }

  .sidebar-list-title {
    font-size: 14px;
    font-weight: 500;
    margin-top: 1px;
    letter-spacing: 0.03rem;
  }

  .sidebar-list-icon svg {
    font-size: 16px;
    text-align: center;
  }

  .collapsed-sidebar-container {
    min-height: 100vh;
    min-width: 50px;
    max-width: 50px;
    transition: all 0.1s ease-in;
  }

  .collapsed-sidebar-container .sidebar-list-section {
    width: 30px;
  }

  .collapsed-sidebar-container .sidebar-list-section .active-sidebar-list {
    display: flex;
    justify-content: center;
    background-color: #2b93c8d0;
    margin: 5px 0px;
    padding: 6px;
    width: 100%;
  }

  .collapsed-sidebar-container .sidebar-list-section .sidebar-list {
    display: flex;
    justify-content: center;
    margin: 5px 0px;
    padding: 6px;
    width: 100%;
  }

  .collapsed-sidebar-container
    .sidebar-list-section
    .collpased-submenu-sidebar-container {
    padding: 6px;
  }

  .sidebar-container .sidebar-list-section a:last-child {
    width: calc(100% - 12px);
  }

  .sidebar-container .sidebar-list-section .submenu-list a:last-child {
    width: calc(100% - 26px);
  }
}
